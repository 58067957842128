import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import {PageTitle} from '../../config/config'
import {SecondAgentName} from '../../config/config'
import { AgentName } from '../../config/config';
const Faq = () => {
  const [expanded, setExpanded] = useState(Array(4).fill(false)); // Array to track expanded state of each div

  const handleToggle = (index) => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const faqData = [
    {
      title: `What is ${PageTitle}?`,
      description: `${PageTitle} makes using ${AgentName} spreadsheets easier. It offers helpful search & filtering options and a simple layout, so shopping through Taobao agents is easy and stress-free.`,
    },
    {
      title: `How does ${PageTitle} work?`,
      description: `${PageTitle} is really easy to use. You can search for specific items or just look through our big collection of over 5000 products. Everything is designed to be simple and easy to find.`,
    },
    {
      title: `Does ${PageTitle} only offer ${PageTitle} links?`,
      description: `No, ${PageTitle} offers an extensive range of links sourced from a variety of reputable platforms, including ${SecondAgentName}, CSSBuy, Sugargoo, Hagobuy, and CNFans, ensuring users have access to a diverse selection of products from trusted sources. When clicking "Shop via Other Agents" or clicking the product image, you will be directed to an individual product page where you will find links to all other Taobao agents.`,
    },
    {
      title: `Can I reach out to ${PageTitle} for assistance or inquiries?`,
      description: `Certainly! You can reach out to us through the "Contact" section. Whether you have inquiries, suggestions, need assistance in finding a specific product, or encounter issues like broken links, we're dedicated to promptly addressing your concerns and ensuring you have a seamless experience.`,
    },
  ];


  return (
<div className='container mx-auto mt-36'>
  <div className='flex-col rounded-3xl shadow-lg' style={{backgroundColor: 'var(--secondary-color)'}}>
    {faqData.map((item, index) => (
      <div key={index} className='faq-item flex flex-col p-4 min-h-[150px]'>
        <div className='flex justify-between w-full pl-6'onClick={() => handleToggle(index)}>
          <h1 className='text-4xl font-medium max-sm:text-[24px]'>{item.title}</h1>
          <div className='h-12 max-sm:h-6 pr-8'>
            <FontAwesomeIcon icon={expanded[index] ? faMinus : faPlus} className='w-10 h-auto' alt="Plus icon" />
          </div>
        </div>
        {expanded[index] && (
          <p style={{color: 'var(--guide-text)'}} className='pl-6 text-2xl max-sm:text-xl mt-3'>{item.description}</p>
        )}
      </div>
    ))}
  </div>
</div>
  );
};

export default Faq;