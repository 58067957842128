import React, { useState } from 'react'
import PandaBuySignUpLink from '../PandaBuySignUpLink/PandaBuySignUpLink'
import Footer from '../Footer/Footer'
import OtherForm from '../ContactForms/OtherForm'
import BrokenlinkForm from '../ContactForms/BrokenlinkForm'
import RequestItemFindForm from '../ContactForms/RequestItemFindForm'
import Navigation from '../Navigation/navigation'

const Contact = () => {

    const [result, setResult] = useState("");
    const [selectedOption, setSelectedOption] = useState('Broken Link');

    const onSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", process.env.REACT_APP_FORMS_KEY);
      formData.append("subject", "New Submission from W2CPANDAREPS contact form");
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      }).then((res) => res.json());
  
      if (res.success) {
        console.log("Success", res);
        setResult(res.message);
      } else {
        console.log("Error", res);
        setResult(res.message);
      }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
      };

    
  return (
    <div>
      <PandaBuySignUpLink />
        <Navigation />
      <div id='Contact' className='container mx-auto flex items-center justify-center  max-sm:pt-12 md:pt-16  '>
        <div className=' shadow-sm bg-[#222222] max-lg:w-[400px] max-sm:w-[350px] w-[800px] h-[770px] rounded-3xl '>

          <div className='moving-dashed-border p-4 '>

          <div className='text-center '>
            <h1 className='my-3 text-3xl text-[#FFF]'>
              Encountered a broken link? Seeking assistance in finding a specific item? Or perhaps you have other suggestions? Feel free to reach out by completing our contact form!
            </h1>
          </div>

          <div className='m-7 w-[334px] mx-auto'>

          <form onSubmit={onSubmit}>

          <div className='mb-7'>
            <label htmlFor='Contact Reason' className='block mb-0 text-[0.688rem] text-[#FFF]'>
              Reason
            </label>
            <select className='border-style w-full px-3 py-3 placeholder-[#fff] placeholder:text-center text-[#fff] font-[ClashLight] bg-[#222222] border border-[#fff]' name="Contact Reason" id="contact-reason" defaultValue={"Broken Link"}
            value={selectedOption} onChange={handleOptionChange}>
                <option value="" className='text-[#fff]' >--Please choose an option--</option>
                <option className='text-[#fff]]' value="Broken Link">Broken Link</option>
                <option className='text-[#fff]]' value="Request for item find">Request for item find</option>
                <option className='text-[#fff]' value="Other">Other</option>
                
                </select>
          </div>
          {selectedOption === 'Broken Link' && <BrokenlinkForm />}
        {selectedOption === 'Request for item find' && <RequestItemFindForm />}
        {selectedOption === 'Other' && <OtherForm />}  
          <p className='text-base text-center text-gray-400' id='result'>
            {result}
          </p>
        </form>


          </div>
          </div>

        </div>
    </div >
        <div className='className="mt-12"'>
        <Footer />
        </div>
      
    </div>
  )
}

export default Contact
