
import { AgentName } from "../../config/config"
export default function PandaBuySihnUpLink() {

    return (
        <nav  className="flex justify-center text-center">
            <h1><a className="underline text-3xl max-sm:text-xl " href="https://www.pandabuy.com/login/?inviteCode=8YCZY5EGU"  target='.blank' rel='norefferer' >{AgentName} Signup link $10 off</a></h1>
        </nav>
    )
}
