import React from 'react'
import Navigation from '../Navigation/navigation'
import Footer from '../Footer/Footer'
import QRCode from '../../assets/qrcode.png'
import PandaBuySignUpLink from '../PandaBuySignUpLink/PandaBuySignUpLink'
import { Helmet } from 'react-helmet';
import PandabuyConfirm from '../../assets/PandabuyConfirm.png'
import PandabuyItem from '../../assets/PandabuyItem.png'
import PandabuyPay from '../../assets/PandabuyPay.png'
import PandabuyPurchase from '../../assets/PandabuyPurchase.png'
import Pandabuyreward from '../../assets/PandabuyReward.png'
import PandabuySearch from '../../assets/PandabuySearch.png'
import { AgentName, PageTitle } from '../../config/config'
const Faq = () => {
  return (
    <div>
    <Helmet>
      <title> {PageTitle}: Step-by-Step, Quality Check and Exclusive Discounts</title>
      <meta name="description" content="Discover the best way to buy from Pandabuy with our comprehensive step-by-step guide. Explore a vast selection of over 5000 unique products on w2cpandareps.com with essential details, QC links, and enjoy exclusive discounts. Find Pandabuy shoes, use discount codes, calculate shipping costs, and more." />
      <meta name="keywords" content="Pandabuy, Pandabuy shoes, Pandabuy discount code, Pandabuy coupon, buy from Pandabuy, Pandabuy shipping, Pandabuy spreadsheet, Pandabuy clothing, step-by-step buying, quality check, shipping process, Pandabuy shipping estimator" />
      <meta name="robots" content="index, follow" />
      {/* Change website name below */}
      <link rel="canonical" href="https://w2cpandareps.com/Guide" />
      <link rel="sitemap" type="application/xml" title="Sitemap" href="https://w2cpandareps.com/sitemap.xml" />
      <script type="application/ld+json" src="/schema.org.jsonld"></script>
    </Helmet>

      <PandaBuySignUpLink/>
      <Navigation />
      <div className='container mx-auto'>

      <div className='flex flex-col pt-24'>
      <div className='flex flex-col '>
        
        <div className='flex justify-evenly max-sm:flex-col max-sm:items-center'>
        <div className="text-left mx-4 max-sm:text-center">
        <h1 className="text-3xl font-bold mt-4 mb-2">Signup Offer</h1>
        <p className='text-xl guideSubheader'>
        Sign up now using my exclusive QR code and enjoy a fantastic <br></br>$10 discount coupon on your very first order with {PageTitle}!  
        </p>

        <h2 className="text-3xl font-bold mt-4 mb-2">How to Redeem:</h2>
        <ul className="text-xl  mb-4 guideSubheader">
          <li>Scan the QR code attached to this message.</li>
          <li>Sign up with {PageTitle}.</li>
          <li>Receive an instant $10 discount on your first order!</li>
        </ul>

        <p className="mb-4 guideSubheader">
          Alternatively, click this link to claim your offer: 
          {/* CHANGE URL FOR AFFILIATE */}
          <a href="https://www.pandabuy.com/?inviteCode=8YCZY5EGU"  target="_blank" rel="noreferrer" className="hover:underline pl-1" style={{color: 'var(--call-to-action-color)'}}>Claim Your $10 Coupon</a>
        </p>

        <p className="mb-4 text-xl guideSubheader">
      Thank you for signing up using my exclusive link! <br></br> Your support means a lot. Now, you're all set to enjoy a fantastic <br></br> $10 discount coupon on your first order with {AgentName}. Happy shopping!
    </p>

      </div>

        <img src={QRCode} alt="QRCode" className="w-[350px] h-[350px] max-sm:w-[250px] max-sm:h-[250px] max-sm:pt-4 " />
        </div>
        <p className='guideTagheader pt-8 max-sm:text-center'>Tags</p>
      <div className='tags pt-2 flex gap-x-4 flex-wrap max-sm:justify-center'>
        <p className='guideTagSubheader max-sm:mt-2'>{AgentName} Coupon</p>
        <p className='guideTagSubheader max-sm:mt-2'>{AgentName} Discount</p>
        <p className='guideTagSubheader max-sm:mt-2'>{AgentName} Signup offer</p>
      </div>

</div>

</div>

  <article>
    <h1 className='text-center faqheader max-sm:text-4xl pt-24  '>A guidance of how to order from {AgentName}</h1>

      <div className='pt-12 max-sm:text-center ' >
      <strong className='guideTitle '>Introduction to {AgentName}.com</strong>
        <p className='guideSubheader'>
          {AgentName}.com is an online marketplace that facilitates easy product purchases from China.
          Renowned for its popularity among shoppers seeking high-quality and affordable products,
          especially clothing, {AgentName}.com has become a trusted platform.
        </p>
        

      <br /><br />
      <strong className='guideTitle'>Wide Product Range and Competitive Pricing</strong>
        <p className="guideSubheader ">{AgentName}.com offers a diverse selection of products, ranging from high-end to more budget-friendly options and exclusive Chinese brands. Its competitive pricing and extensive range make it a favorite for shoppers.</p>

      

      <br /><br />
      <strong className='guideTitle'>Quality Check Assurance</strong>
      <br />
      <p className="guideSubheader">A key advantage of using {AgentName}.com is its commitment to quality control. The platform employs a team of experts who meticulously inspect each product before shipment, ensuring that customers receive items that meet the highest standards.</p>

      

      <br /><br />
      <strong className='guideTitle'>Secure and Reliable Shopping Experience</strong>
      <br />
      <p className="guideSubheader">{AgentName}.com prioritizes a secure shopping environment. Utilizing advanced encryption and security measures, the website safeguards customers' personal and financial information. Fast and reliable shipping further enhances the overall shopping experience, with most orders delivered within a few days.</p>
      

      <br /><br />
      <strong className='guideTitle'>Why Choose {AgentName}.com?</strong>
      <br />
      <p className="guideSubheader">{AgentName}.com stands out as an excellent platform for those seeking high-quality products at affordable prices. Its commitment to quality control, extensive product selection, reliable service, and secure transactions have contributed to its popularity among shoppers. If you're looking to save money on clothing purchases, {AgentName}.com is a platform worth exploring.</p>

      <p className='guideTagheader pt-8 max-sm:text-center'>Tags</p>
      <div className='tags pt-2 flex gap-x-4 flex-wrap max-sm:justify-center'>
      <p className='guideTagSubheader max-sm:mt-2'>{AgentName} Guide</p>
      <p className='guideTagSubheader max-sm:mt-2'>Online Shopping Tips</p>
      <p className='guideTagSubheader max-sm:mt-2'>High-quality Products</p>
      <p className='guideTagSubheader max-sm:mt-2'>Secure Shopping</p>
      <p className='guideTagSubheader max-sm:mt-2'>Affordable Clothing</p>
      </div>

      </div>  

  </article>


  <article className='flex flex-col pt-24 '>
      <h1 className='text-center faqheader max-sm:text-4xl max-sm:items-center'>How to Buy from {AgentName}: A Step-by-Step Guide</h1>
      <p className='faqsubheader pt-12 max-sm:text-center '>
        <strong className='guideTitle'><span className='mr-2 '>01.</span>Finding the Items:</strong>
        <br />
        <p className="guideSubheader">
      Browse China's online e-commerce platforms, such as Taobao / Tmall / JD.com.
      Find your ideal sellers info & products through social media, e.g., Reddit, Instagram or TikTok. 
      Copy the product link or name/shop's link/the order number and paste it into the pandabuys search bar. 
      <br /> Alternatively browse <a href="/products"><span style={{color: 'var(--call-to-action-color)'}} className=' w-[15px]'>{PageTitle}</span></a> for direct links to Pandabuy products
      </p> 
      <img src={PandabuySearch} alt="Pandabuy Search" className='h-[400px] max-sm:h-[350px] w-full mt-2' />
        <br /><br />
        <strong className='guideTitle'><span className='mr-2'>02.</span>Submit Your Order</strong>
        <br />
        <p className="guideSubheader">
            When submitting your order, you have the option to directly select the color, size, and quantity of your desired items, and even add special remarks.
             Simply click “Buy Now” or “Add to Cart”. At {AgentName},
             {AgentName} prioritize your shopping convenience. To ensure your satisfaction,
             They provide Quality Check pictures of previously sold and shipped items.
             Before placing your order, 
             you'll also have access to crucial information such as sales data, average shipping time, weight, volume, net weight/volume. 
             Plus, for our esteemed customers with 300 VIP points and above, you can view in-warehouse photos before finalizing your purchase!
        </p> 
        <img src={PandabuyItem} alt="Pandabuy Search" className='h-[450px] max-sm:h-[350px] w-full mt-2' />
        

        <br /><br />
        <strong className='guideTitle'><span className='mr-2'>03.</span>Confirm Order</strong>
        <br />
        <p className="guideSubheader">
        Choose the destination country. Upon purchase, your items will be initially dispatched by the seller to the {AgentName} warehouse in China.
        Once the order has been received and stored in our warehouse, you can conveniently access it through the 
        [Personal Center - Warehouse] page. From there, you can proceed to submit an international parcel request and provide the necessary detailed address for shipping.
        If you desire more comprehensive pictures beyond Quality Check photos, simply opt for Extra Photos before finalizing your order."
        </p> 
        <img src={PandabuyConfirm} alt="Pandabuy Search" className='h-[450px] max-sm:h-[350px] w-full mt-2' />

        <br /><br />
        <strong className='guideTitle'><span className='mr-2'>04.</span>Payment Process</strong>
        <br />
        <p className="guideSubheader">
        Choose your country and select a preferred payment method. 
        You'll be required to cover the fee, which comprises the product cost and domestic shipping fee (both charged by the seller).
        Please note that the international shipping fee is not included. <br/>
        <span className='text-[#ffffff]'>Accepted Payment Methods:</span> PayPal, Credit Cards, Alipay Plus, Stripe, UnionPay, Alipay, Applepay and more.
        </p> 
        <img src={PandabuyPay} alt="Pandabuy Search" className='h-[450px] max-sm:h-[350px] w-full mt-2' />
        <br /><br />


        
        <strong className='guideTitle'><span className='mr-2'>05.</span>{AgentName} Purchase</strong>
        <br />
        <p className="guideSubheader">
        After confirming your order, {AgentName}s team initiates processing within 6 working hours. 
        They contact the seller to acquire your items, and subsequently await their shipment to our facility.
        Typically, this shipping process takes approximately 3 to 5 working days.
        Throughout the Purchase process, you'll receive internal messages promptly should any issues arise, such as requiring additional information from your end.
        For further inquiries or clarifications, feel free to click on 'Contact' to reach out to our shopping agents.
        They will diligently respond to your queries within 24 hours through the order enquiry section.
        </p> 
        <img src={PandabuyPurchase} alt="Pandabuy Search" className='h-[450px] max-sm:h-[350px] w-full mt-2' />
        <br /><br />


        
        <strong className='guideTitle'><span className='mr-2'>06.</span>{AgentName} Quality Assurance and Warehousing</strong>
        <br />
        <p className="guideSubheader">
        {AgentName} ensures rigorous quality checks for your items, accessible for review in the [Personal Center - Warehouse] section upon storage.
        Upon acceptance of the product, Pandabuy will securely store it in the warehouse.
        In case of dissatisfaction, {AgentName} dedicated agents team will assist in negotiating with the seller for returns or exchanges.
        Once your items reach the {AgentName} warehouse, {AgentName} provide complimentary storage for up to 90 days.
        This enables you to consolidate your purchases and ship them together in a single parcel, thereby optimizing shipping costs.
        </p> 
        <br /><br />

        
        <strong className='guideTitle'><span className='mr-2'>07.</span>Submit for Delivery</strong>
        <br />
        <p className="guideSubheader">
        Once your items are marked as 'Stored', navigate to the 'Warehouse' section. 
        Select the desired item and proceed to click on "submit" to organize international delivery. 
        This involves entering the overseas address, specifying package details, selecting a preferred shipping method, 
        initiating declaration procedures, and settling the international shipping fee.
        </p> 
        <br /><br />

        
        <strong className='guideTitle'><span className='mr-2'>08.</span>Pay the International Shipping Cost</strong>
        <br />
        <p className="guideSubheader">
        Choose your preferred payment method to cover the international shipping fee.
        The international shipping fee is determined based on factors such as the estimated weight of the product, the chosen shipping method, and the destination.
        For assistance in estimating the shipping fee, please refer to <a style={{color: 'var(--call-to-action-color)'}}  href='https://www.pandabuy.com/estimation' target="_blank" rel="noreferrer">https://www.pandabuy.com/estimation </a>
        for calculating shipping estimation.
        </p> 
        <br></br>

        <strong className='guideTitle'><span className='mr-2'>09.</span>Delivery and Rewards</strong>
        <p className="guideSubheader">
        Upon submission of your delivery order, {AgentName} meticulously package and dispatch your items. 
        Track your package's journey and monitor its shipping progress conveniently through the "Parcel" page,
        using the provided tracking number.
        Once your parcel is safely received, 
        feel free to share your shopping experiences by reviewing the product on {AgentName} and apply for rewards.
        </p> 
        <img src={Pandabuyreward} alt="Pandabuy Search" className='h-[450px] max-sm:h-[150px] w-full mt-2' />
        <br /><br />
        <p className="guideSubheader">By following this comprehensive guide and utilizing <a href="https://w2cpandareps.com" className='text-[#A259FF]' target="_blank" rel="noopener noreferrer">{PageTitle}</a>, you'll confidently navigate the process of buying items from {AgentName}. Save your favorite items to the watchlist on {PageTitle} for quick access, easy comparison, and to check prices. Ensure a seamless and enhanced shopping experience with over 5000 unique products at your fingertips. Happy shopping!</p> 
      </p>

      <p className='guideTagheader pt-8 max-sm:text-center'>Tags</p>
      <div className='tags pt-2 flex gap-x-4 flex-wrap max-sm:justify-center'>
        <p className='guideTagSubheader max-sm:mt-2'>{AgentName} Guide</p>
        <p className='guideTagSubheader max-sm:mt-2'>Online Shopping Tips</p>
        <p className='guideTagSubheader max-sm:mt-2'>Step-by-Step Buying</p>
        <p className='guideTagSubheader max-sm:mt-2'>Quality Check</p>
        <p className='guideTagSubheader max-sm:mt-2'>Shipping Process</p>
        <p className='guideTagSubheader max-sm:mt-2'>{AgentName} Shipping Estimator</p>
      </div>
      
</article>




      </div>
      <Footer />
    </div>
  )
}

export default Faq