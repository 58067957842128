import React, { useState, useEffect } from 'react';
import Marquee from "react-fast-marquee";
import Card from '../../components/Cards/Card';

const FeaturedItems = () => {
    const [featuredItems, setFeaturedItems] = useState([]);

    useEffect(() => {
        import(`../../assets/ProductData/FeaturedItems.json`)
        .then((module) => {
          const data = module.default;
          setFeaturedItems(data);
        })
    }, []);

    const handleTouchMove = (e) => {
        e.stopPropagation();
    };

    return (
        <div className='container mx-auto mt-36 ' >
            <div className='heroHeader'>
            <h1 className='heroTxt max-sm:text-4xl max-lg:pl-9 mb-12'>Featured Items</h1>
            </div>
            
            <Marquee
            /* Adjust color to background */
                gradientColor='#2B2B2B'
                gradient
                onTouchMove={handleTouchMove}
            >
                {featuredItems.map((item) => (
                    <Card 
                        key={item.productId} 
                        sugargooAffiliateLink={item.sugargooAffiliateLink}
                        cnFansAffiliateLink={item.cnFansAffiliateLink} 
                        hagoBuyAffiliateLink={item.hagoBuyAffiliateLink} 
                        superBuyAffiliateLink={item.superBuyAffiliateLink}
                        cssBuyAffiliateLink={item.cssBuyAffiliateLink}
                        pandaBuyAffiliateLink={item.pandaBuyAffiliateLink}
                        qcLink={item.qcLink} 
                        itemName={item.itemName} 
                        sales={item.sales}
                        weight={item.weight} 
                        pic_url={item.pic_url}
                        dimensions={item.dimensions}
                        searchName={item.searchName} 
                        itemCategory={item.itemCategory}
                        price={item.price}
                        productId={item.productId}
                    />
                ))}
            </Marquee>
        </div>
    );
};

export default FeaturedItems;
