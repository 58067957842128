import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectProducts,
} from '../../features/productSlice';
import {
  setSearchField,
} from '../../features/searchSlice';
import {
  setCategory,
  selectCategory,
} from '../../features/categorySlice';
import {
  setFilter,
  selectFilter,
} from '../../features/filterSlice';
import CardList from '../Cards/CardList';
import SearchBox from './SearchBox/SearchBox';
import Navigation from '../Navigation/navigation';
import Footer from '../Footer/Footer';
import Categories from './Categories/Categories';
import PandaBuySignUpLink from '../PandaBuySignUpLink/PandaBuySignUpLink';
import Filter from './Filter/Filter';
import { Helmet } from 'react-helmet';
import debounce from 'lodash.debounce';

const Products = () => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector(selectCategory);
  const selectedFilter = useSelector(selectFilter);
  const productsState = useSelector(selectProducts);
  const [search, setSearch] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    applyFilters();
  }, [productsState.products, selectedCategory, selectedFilter, search]);  

  const applyFilters = () => {
    let filteredProducts = [...productsState.products];

    if (selectedCategory !== 'All') {
      filteredProducts = filteredProducts.filter(product => product.itemCategory === selectedCategory);
    }

    if (selectedFilter === 'Alphabetical Order') {
      filteredProducts.sort((a, b) => a.itemName.toLowerCase().localeCompare(b.itemName.toLowerCase()));  
    } else if (selectedFilter === 'Price Ascending') {
      filteredProducts.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } else if (selectedFilter === 'Price Descending') {
      filteredProducts.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    } else if (selectedFilter === 'Sales Ascending') {
      filteredProducts.sort((a, b) => a.sales - b.sales);
    } else if (selectedFilter === 'Sales Descending') {
      filteredProducts.sort((a, b) => b.sales - a.sales);
    }

    if (search.trim() !== '') {
      filteredProducts = filteredProducts.filter(product =>
        product.searchName.replace(/\s/g, '').toLowerCase().includes(search.replace(/\s/g, '').toLowerCase())
      );
    }

    setFilteredProducts(filteredProducts);
  };

  const handleSearchChange = debounce((search) => {
    dispatch(setSearchField(search)); // Uppdatera sökfältet i Redux-storen (om det behövs)
    setSearch(search); // Uppdatera lokalt sökfält
  }, 300);

  const handleCategoryChange = (selectedCategory) => {
    dispatch(setCategory(selectedCategory));
  };

  const handleFilterChange = (selectedFilter) => {
    dispatch(setFilter(selectedFilter));
  };

  return (
    <>
      <Helmet>
        <title>Explore a Comprehensive Web-Based Spreadsheet: 5000+ Fashion Reps from Pandabuy, Hagobuy, CSSBuy, and More</title>
        <meta name="description" content="Discover an extensive collection of fashion replicas on our web-based spreadsheet, featuring over 5000 products sourced from Pandabuy, Hagobuy, CNFans, Sugargoo, Superbuy, and CSSBuy. Explore quality check links, QC services, and easily track products by adding them to your watchlist." />
        <meta name="keywords" content="Pandabuy shoes, Hagobuy tracksuit, CNFans Stussy clothing, Sugargoo Trapstar, Superbuy QC photos, CSSBuy Moncler, fashion replicas, quality check links, Taobao agents, Weidian agents, watchlist, website spreadsheet" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://w2cpandareps.com/products" />
        <link rel="sitemap" type="application/xml" title="Sitemap" href="https://w2cpandareps.com/sitemap.xml" />
        <script type="application/ld+json" src="/schema.org.jsonld"></script>
      </Helmet>
      <PandaBuySignUpLink />
      <Navigation />
      <SearchBox setSearchField={setSearchField} onSearchSubmit={handleSearchChange} />
      <Categories setCategory={setCategory} onCategorySelect={handleCategoryChange} category={selectedCategory} />
      <Filter setFilter={setFilter} onFilterSelect={handleFilterChange} filter={selectedFilter} />
      <CardList products={filteredProducts} search={search}  />
      <Footer />
    </>
  );
};

export default Products;
